import { Banner } from '@krakentech/coral';
import {
    SbBlokData,
    StoryblokComponent,
    storyblokEditable,
} from '@storyblok/react';
import { BannerStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicBannerProps = {
    blok: BannerStoryblok;
};

const DynamicBanner = ({ blok }: DynamicBannerProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Banner" field="Blocks" />;
    }

    return (
        <div {...storyblokEditable(blok)}>
            <Banner variant="secondary" textAlign={blok.textAlign}>
                {blok.blocks.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent
                        blok={nestedBlok}
                        key={nestedBlok._uid}
                    />
                ))}
            </Banner>
        </div>
    );
};

export default DynamicBanner;
