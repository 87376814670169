import { Tooltip } from '@krakentech/coral';
import {
    SbBlokData,
    StoryblokComponent,
    storyblokEditable,
} from '@storyblok/react';
import { TooltipStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicTooltipProps = {
    blok: TooltipStoryblok;
};

const DynamicTooltip = ({ blok }: DynamicTooltipProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Tooltip" field="Blocks" />;
    }
    return (
        <div {...storyblokEditable(blok)}>
            <Tooltip title={blok.title}>
                <>
                    {blok.blocks.map((nestedBlok: SbBlokData) => (
                        <StoryblokComponent
                            blok={nestedBlok}
                            key={nestedBlok._uid}
                        />
                    ))}
                </>
            </Tooltip>
        </div>
    );
};

export default DynamicTooltip;
