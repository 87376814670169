import { storyblokEditable } from '@storyblok/react';
import { getYouTubeVideoId } from './utils';
import {
    StyledContainer,
    StyledInner,
    StyledYouTubeEmbed,
} from './index.styled';

import { YouTubeEmbedStoryblok } from '@/types/generated/storyblok/schema';

type DynamicYouTubeEmbedProps = {
    blok: YouTubeEmbedStoryblok;
};

const DynamicYouTubeEmbed = ({ blok }: DynamicYouTubeEmbedProps) => {
    const id = getYouTubeVideoId(blok.youTubeUrl);

    return (
        <StyledContainer align={blok.align} {...storyblokEditable(blok)}>
            <StyledInner size={blok.size}>
                <StyledYouTubeEmbed>
                    <iframe
                        src={`https://www.youtube.com/embed/${id}?start=${blok.startAt?.toString()}&modestbranding=1&color=white&playsinline=1`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </StyledYouTubeEmbed>
            </StyledInner>
        </StyledContainer>
    );
};

export default DynamicYouTubeEmbed;
