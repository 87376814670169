import { INTERNAL_PATHS } from '@/utils/urls';

export const mainLinkMenu = [
    {
        text: 'About',
        href: INTERNAL_PATHS.ABOUT_US.path,
    },
    {
        text: 'FAQs',
        href: INTERNAL_PATHS.FAQS.path,
    },
    {
        text: 'Signatories',
        href: INTERNAL_PATHS.SIGNATORIES.path,
    },
];

export const subLinkMenu = [
    {
        text: 'Contact us',
        href: INTERNAL_PATHS.CONTACT_US.path,
    },
    {
        text: 'Privacy Policy',
        href: INTERNAL_PATHS.PRIVACY_POLICY.path,
    },
    {
        text: 'Terms & Conditions',
        href: INTERNAL_PATHS.TERMS.path,
    },
];

export const socialMenu = [];
