import { Chip } from '@krakentech/coral';
import { storyblokEditable } from '@storyblok/react';
import { ChipStoryblok } from '@/types/generated/storyblok/schema';

type DynamicChipProps = {
    blok: ChipStoryblok;
};

const DynamicChip = ({ blok }: DynamicChipProps) => (
    <div {...storyblokEditable(blok)}>
        <Chip color={blok.color ? blok.color : 'primary'} />
    </div>
);

export default DynamicChip;
