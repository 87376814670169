import { Container } from '@krakentech/coral';
import TechZeroHeader from '../Header';
import Footer from '../Footer';
import SkipToContent from '../SkipToContent';

type PageLayoutProps = {
    children: React.ReactNode;
};

const PageLayout = ({ children }: PageLayoutProps) => (
    <Container theme="none" fullWidth={true}>
        <SkipToContent />
        <TechZeroHeader />
        {children}
        <Footer />
    </Container>
);

export default PageLayout;
