import { storyblokEditable } from '@storyblok/react';
import Typeform from '@/components/Typeform';

import { TypeformStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicTypeformProps = {
    blok: TypeformStoryblok;
};

const DynamicTypeform = ({ blok }: DynamicTypeformProps) => {
    if (!blok?.form_id) {
        return <StoryblokMissingField blok="Typeform" field="Form ID" />;
    }
    if (!blok?.height) {
        return <StoryblokMissingField blok="Typeform" field="Height" />;
    }
    return (
        <Typeform
            {...storyblokEditable(blok)}
            id={blok.form_id}
            height={blok.height}
        />
    );
};

export default DynamicTypeform;
