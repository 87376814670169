import { INTERNAL_PATHS } from '@/utils/urls';

export const menus = {
    mainMenu: [
        {
            text: 'Home',
            href: INTERNAL_PATHS.HOME.path,
        },
        {
            text: 'Join Tech Zero',
            href: INTERNAL_PATHS.JOIN.path,
        },
        {
            text: 'Commitments',
            href: INTERNAL_PATHS.COMMITMENTS.path,
        },
        {
            text: 'Signatories',
            href: INTERNAL_PATHS.SIGNATORIES.path,
        },
        {
            text: 'About',
            href: INTERNAL_PATHS.ABOUT_US.path,
        },
        {
            text: 'Resources',
            href: INTERNAL_PATHS.RESOURCES.path,
        },
    ],
    subMenu: [
        {
            text: 'Contact us',
            href: INTERNAL_PATHS.CONTACT_US.path,
        },
        {
            text: 'FAQs',
            href: INTERNAL_PATHS.FAQS.path,
        },
    ],
    logoLink: INTERNAL_PATHS.HOME.path,
} as const;
