import { Typography } from '@krakentech/coral';

import { StyledSkipToContent } from './index.styled';

import Link from '@/components/Link';

const SkipToContent = () => (
    <StyledSkipToContent>
        <Typography component="span">
            <Link href="#maincontent">Skip to main content</Link>
        </Typography>
    </StyledSkipToContent>
);

export default SkipToContent;
