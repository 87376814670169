import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { Typography } from '@krakentech/coral';

import {
    StyledFigure,
    StyledImageContainer,
    StyledSvg,
    StyledFigCaption,
} from './index.styled';

import { optimizeStoryblokImage } from '@/utils/storyblok';
import { BLUR_DATA_URI } from '@/utils/constants';

import StoryblokMissingField from '@/components/StoryblokMissingField';

interface ImageProps extends NextImageProps {
    src: string;
    alt: string;
    width?: number;
    height?: number;
    caption?: string;
}

const StoryblokImage = ({
    src,
    alt,
    width,
    height,
    caption,
    ...restProps
}: ImageProps) => {
    if (!src || !alt) {
        return <StoryblokMissingField blok="Image" field="Image" />;
    }

    const isSvg = /(.svg)$/.test(src);

    if (isSvg) {
        return (
            <StyledSvg
                src={src}
                alt={alt}
                width={width ?? undefined}
                height={height ?? undefined}
            />
        );
    }

    src = optimizeStoryblokImage(src);

    let imageDimensions = {
        width: parseInt(src.split('/')[5].split('x')[0]),
        height: parseInt(src.split('/')[5].split('x')[1]),
    };

    const aspectRatio = imageDimensions.width / imageDimensions.height;

    // if both a width and height override is set, use both values for this image
    if (width && height) {
        imageDimensions = {
            width: width,
            height: height,
        };
    }

    // if only a width override is set, set the height using the original aspect ratio
    if (width && !height) {
        imageDimensions = {
            width: width,
            height: Math.ceil(width) / aspectRatio,
        };
    }

    // if only a height override is set, set the width using the original aspect ratio
    if (!width && height) {
        imageDimensions = {
            width: Math.ceil(height * aspectRatio),
            height: height,
        };
    }

    return (
        <StyledFigure width={imageDimensions.width}>
            <StyledImageContainer
                maxWidth={imageDimensions.width}
                maxHeight={imageDimensions.height}
            >
                <NextImage
                    src={src}
                    alt={alt}
                    width={imageDimensions.width}
                    height={imageDimensions.height}
                    placeholder="blur"
                    blurDataURL={BLUR_DATA_URI}
                    {...restProps}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </StyledImageContainer>
            {caption && (
                <StyledFigCaption>
                    <Typography variant="body2" color="inherit">
                        {caption}
                    </Typography>
                </StyledFigCaption>
            )}
        </StyledFigure>
    );
};

export default StoryblokImage;
