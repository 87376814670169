import { storyblokEditable } from '@storyblok/react';
import { Container } from '@krakentech/coral';
import { GreenJobsMap } from './components/greenJobsMap';
import { SignatoryStoryblok } from '@/types/generated/storyblok/schema';

type DynamicSignatoryProps = {
    blok: SignatoryStoryblok;
};

const DynamicGreenJobsMap = ({ blok }: DynamicSignatoryProps) => {
    return (
        <Container paddingBottom="xxxl" {...storyblokEditable(blok)}>
            <GreenJobsMap />
        </Container>
    );
};

export default DynamicGreenJobsMap;
