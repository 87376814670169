import { Container } from '@krakentech/coral';

interface Props {
    children: React.ReactElement;
}

export const PageContainer: React.FC<Props> = ({
    children,
}: {
    children: React.ReactElement;
}) => {
    return (
        <Container
            theme="none"
            paddingX="md"
            marginX="auto"
            sm={{ width: 576 }}
            md={{ width: 768 }}
            lg={{ width: 992 }}
            xl={{ width: 1200 }}
        >
            {children}
        </Container>
    );
};
