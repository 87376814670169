import { Widget } from '@typeform/embed-react';

type TypeformProps = {
    id: string;
    height?: number;
};

const Typeform = ({ id, height = 600 }: TypeformProps) => {
    return (
        <Widget
            id={id}
            style={{ height: `${height}px` }}
            hideFooter={true}
            hideHeaders={true}
            opacity={0}
        />
    );
};

export default Typeform;
