import styled from 'styled-components';
import { YouTubeEmbedStoryblok } from '@/types/generated/storyblok/schema';

const sizeMapping = {
    small: '460px',
    medium: '760px',
    full: 'none',
};

export const StyledContainer = styled.div<Pick<YouTubeEmbedStoryblok, 'align'>>`
    display: flex;
    justify-content: ${({ align }) => align};
`;

export const StyledInner = styled.div<Pick<YouTubeEmbedStoryblok, 'size'>>`
    width: 100%;
    max-width: ${({ size }) => {
        const sizeCond = size ? size : 'medium';
        return sizeMapping[sizeCond];
    }};
`;

export const StyledYouTubeEmbed = styled.div`
    position: relative;
    aspect-ratio: 16 / 9;
    width: 100%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
