import { storyblokEditable } from '@storyblok/react';

import Link from '@/components/Link';
import { LinkStoryblok } from '@/types/generated/storyblok/schema';

type DynamicLinkProps = {
    blok: LinkStoryblok;
};

const DynamicLink = ({ blok }: DynamicLinkProps) => (
    <div {...storyblokEditable(blok)}>
        <Link href={blok.href || '/'} color={blok.color}>
            {blok.name}
        </Link>
    </div>
);

export default DynamicLink;
