import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { Stack } from '@krakentech/coral';
import { PageStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';
import { PageContainer } from '@/components/PageContainer';

type DynamicPageProps = {
    blok: PageStoryblok;
};

const DynamicPage = ({ blok }: DynamicPageProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Page" field="Blocks" />;
    }
    return (
        <div>
            {blok.blocks.map(
                (nestedBlok: SbBlokData) =>
                    nestedBlok.component === 'hero' && (
                        <StoryblokComponent
                            blok={nestedBlok}
                            key={nestedBlok._uid}
                        />
                    )
            )}
            <Stack
                direction="vertical"
                justifyContent="center"
                alignItems="center"
            >
                <PageContainer>
                    <>
                        {blok.blocks.map(
                            (nestedBlok: SbBlokData) =>
                                nestedBlok.component !== 'hero' && (
                                    <StoryblokComponent
                                        blok={nestedBlok}
                                        key={nestedBlok._uid}
                                    />
                                )
                        )}
                    </>
                </PageContainer>
            </Stack>
        </div>
    );
};

export default DynamicPage;
