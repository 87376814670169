import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { Analytics } from '@vercel/analytics/react';
import { GlobalCss } from '@krakentech/coral';
import { config } from '@fortawesome/fontawesome-svg-core';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { storyblokInit, apiPlugin } from '@storyblok/react';
import { DehydratedState, Hydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { coralBloks } from '@krakentech/coral-storyblok';
import { GA_TRACKING_ID, pageView } from '@/utils/gtag';
import PageLayout from '@/components/PageLayout';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Providers from '@/components/Providers';
import { components } from '@/components/bloks/index';
import NextProgressBar from '@/components/NextProgressBar';
import { maintenanceMode } from '@/featureFlags';
import { MaintenanceCover } from '@/components/MaintenanceCover';

config.autoAddCss = false;

storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_ACCESS_TOKEN,
    use: [apiPlugin],
    components: {
        ...components,
        ...coralBloks,
    },
});

type PageProps = {
    dehydratedState?: DehydratedState;
};

function App({ Component, pageProps }: AppProps<PageProps>) {
    const router = useRouter();

    /**
     * Handle route / hash changes in the browser
     * and log those in Google Analytics.
     */
    useEffect(() => {
        const handleRouteChange = (url: string) => {
            pageView({ url });
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        router.events.on('hashChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
            router.events.off('hashChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            <Script
                strategy="afterInteractive"
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />
            <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', '${GA_TRACKING_ID}', {
                            page_path: window.location.pathname,
                        });
                    `,
                }}
            />
            <Analytics />
            <Providers>
                <NextProgressBar />
                {/* Will be fixed in Coral here: https://app.asana.com/0/1200668202270525/1202659080625512/f */}
                {/* @ts-ignore */}
                <GlobalCss />
                {maintenanceMode ? (
                    <MaintenanceCover />
                ) : (
                    <PageLayout>
                        <Hydrate state={pageProps.dehydratedState}>
                            <Component {...pageProps} />
                        </Hydrate>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </PageLayout>
                )}
            </Providers>
        </>
    );
}

// appWithTranslation HOC does not like the additional PageProps type within AppProps
// @ts-ignore
export default appWithTranslation(App);
