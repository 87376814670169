import { CoralSpacing } from '@krakentech/coral';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import { WellStoryblok } from '@/types/generated/storyblok/schema';

import StoryblokMissingField from '@/components/StoryblokMissingField';
import Well from '@/components/Well';
import { WellTheme } from '@/components/Well/index.types';

type DynamicWellProps = {
    blok: Omit<WellStoryblok, 'theme' | 'padding' | 'margin'> & {
        padding?: keyof CoralSpacing | 'default';
        margin?: keyof CoralSpacing | 'default';
        theme?: WellTheme;
    };
};

const DynamicWell = ({ blok }: DynamicWellProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Well" field="Blocks" />;
    }
    return (
        <Well
            padding={blok.padding || 'default'}
            margin={blok.margin || 'default'}
            theme={blok.theme || 'none'}
        >
            {blok.blocks.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Well>
    );
};

export default DynamicWell;
