import { Stack } from '@krakentech/coral';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { StackStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicStackProps = {
    blok: StackStoryblok;
};

const DynamicStack = ({ blok }: DynamicStackProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Stack" field="Blocks" />;
    }
    return (
        <Stack
            direction={blok.direction}
            gap={blok.gap === '0' ? 0 : blok.gap}
            justifyContent={blok.justifyContent}
            fullHeight={blok.fullHeight}
        >
            {blok.blocks.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Stack>
    );
};

export default DynamicStack;
