/**
 * YouTube links come in 2 flavours, youtube.com/watch?v=MfKgQ9Y5Luc and youtu.be/MfKgQ9Y5Luc.
 * This util extracts the YouTube video ID ready to give to the embed code render.
 *
 * @param url A YouTube video url (eg: https://www.youtube.com/watch?v=MfKgQ9Y5Luc | https://youtu.be/MfKgQ9Y5Luc)
 * @returns string A YouTube video ID (eg: MfKgQ9Y5Luc)
 */
export const getYouTubeVideoId = (url?: string): string => {
    const splitBy = url?.includes('youtu.be') ? '/' : 'v=';
    return url?.split(splitBy).pop() as string;
};
