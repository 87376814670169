import { Grid } from '@krakentech/coral';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import {
    GridItemStoryblok,
    GridStoryblok,
} from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicGridProps = {
    blok: GridStoryblok;
};

export const DynamicGridItem = ({
    blok: {
        _uid,
        blocks,
        colSpan,
        rowSpan,
        colSpanMedium,
        colSpanSmall,
        rowSpanMedium,
        rowSpanSmall,
    },
}: GridItemStoryblok) => {
    if (!blocks || blocks?.length < 1) {
        return <StoryblokMissingField blok="Grid Item" field="Blocks" />;
    }
    return (
        <Grid.Item
            colSpan={colSpan}
            rowSpan={rowSpan}
            md={{
                colSpan: colSpanMedium,
                rowSpan: rowSpanMedium,
            }}
            sm={{
                colSpan: colSpanSmall,
                rowSpan: rowSpanSmall,
            }}
            key={_uid}
        >
            {blocks.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Grid.Item>
    );
};

const DynamicGrid = ({
    blok: { columns, rows, gap, gridItems },
}: DynamicGridProps) => {
    if (!gridItems || gridItems?.length < 1) {
        return <StoryblokMissingField blok="Grid" field="Grid Items" />;
    }
    if (!gap) {
        return <StoryblokMissingField blok="Grid" field="Spacing" />;
    }
    return (
        <Grid
            templateColumns={`${columns ? `repeat(${columns}, 1fr` : ''})`}
            templateRows={`${rows ? `repeat(${rows}, 1fr` : ''})`}
            gap={gap === '0' ? 0 : gap}
        >
            {gridItems.map((gridItem: GridItemStoryblok) => (
                <StoryblokComponent blok={gridItem} key={gridItem._uid} />
            ))}
        </Grid>
    );
};

export default DynamicGrid;
