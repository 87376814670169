import { Accordion } from '@krakentech/coral';
import {
    StoryblokComponent,
    storyblokEditable,
    SbBlokData,
} from '@storyblok/react';
import { AccordionStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicAccordionProps = {
    blok: AccordionStoryblok;
};

const DynamicAccordion = ({ blok }: DynamicAccordionProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Accordion" field="Blocks" />;
    }
    return (
        <div {...storyblokEditable(blok)}>
            <Accordion
                title={blok.title}
                disabled={blok.disabled}
                expanded={blok.defaultExpanded}
            >
                {blok.blocks.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent
                        blok={nestedBlok}
                        key={nestedBlok._uid}
                    />
                ))}
            </Accordion>
        </div>
    );
};

export default DynamicAccordion;
