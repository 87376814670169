import styled from 'styled-components';

interface StyledLinkProps {
    color: 'dark' | 'light';
}

export const StyledLink = styled.a<StyledLinkProps>`
    color: ${({ color, theme }) =>
        color === 'light'
            ? theme.color.tertiary.main
            : theme.color.secondary.main};
    text-decoration: underline;
`;
