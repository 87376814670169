import { CoralConfigOptions, CoralColors } from '@krakentech/coral';
import { techZeroColors } from '@/utils/constants';

const colors: Partial<CoralColors> = {
    base: {
        main: techZeroColors.green.main,
        dark: techZeroColors.black.main,
    },
    primary: {
        main: techZeroColors.green.main,
        main10: techZeroColors.green.dark,
        light: techZeroColors.white.main,
        dark: techZeroColors.black.main,
        intense: techZeroColors.black.main,
    },
    secondary: {
        main: techZeroColors.green.main,
        main10: techZeroColors.green.main,
        light: techZeroColors.white.main,
        dark: techZeroColors.black.main,
    },
    tertiary: {
        main: techZeroColors.white.main,
        light: techZeroColors.green.dark,
        light10: techZeroColors.green.main,
    },
    text: {
        main: techZeroColors.white.main,
        light: techZeroColors.white.main,
        mid: techZeroColors.green.main,
        mid20: techZeroColors.green.dark,
        dark: techZeroColors.black.main,
    },
    surface: {
        main: techZeroColors.white.main,
        light: techZeroColors.green.main,
        mid: techZeroColors.green.dark,
        dark: techZeroColors.black.main,
    },
};

const theme: Partial<CoralConfigOptions> = {
    overrides: {
        animation: {
            easing: 'ease-out',
            durationMs: 250,
        },
        color: colors,
        components: {
            alert: {
                root: {
                    backgroundColor: techZeroColors.green.main,
                },
            },
            banner: {
                tertiary: {
                    backgroundColor: techZeroColors.white.main,
                },
            },
            button: {
                root: {},
                primary: {
                    contained: {
                        backgroundColor: techZeroColors.white.main,
                        color: techZeroColors.green.main,
                        borderColor: techZeroColors.white.main,
                        ':hover': {
                            color: techZeroColors.green.dark,
                            textDecoration: 'underline',
                        },
                    },
                    ghost: {
                        backgroundColor: techZeroColors.black.main,
                        ':hover': {
                            backgroundColor: techZeroColors.black.main,
                            textDecoration: 'underline',
                        },
                    },
                    outlined: {
                        backgroundColor: techZeroColors.white.main,
                        color: techZeroColors.green.main,
                        borderColor: techZeroColors.black.main,
                        ':hover': {
                            backgroundColor: techZeroColors.black.main,
                            color: techZeroColors.green.main,
                            borderColor: techZeroColors.white.main,
                        },
                    },
                    link: {
                        color: techZeroColors.green.main,
                        ':hover': {
                            color: techZeroColors.green.dark,
                        },
                    },
                    text: {
                        color: techZeroColors.green.main,
                        ':hover': {
                            color: techZeroColors.green.dark,
                        },
                    },
                },
                secondary: {
                    contained: {
                        borderColor: techZeroColors.green.main,
                        ':hover': {
                            backgroundColor: techZeroColors.green.dark,
                            borderColor: techZeroColors.green.dark,
                        },
                    },
                    ghost: {
                        backgroundColor: techZeroColors.green.main,
                        color: techZeroColors.white.main,
                        ':hover': {
                            backgroundColor: techZeroColors.green.dark,
                            color: techZeroColors.white.main,
                        },
                    },
                    outlined: {
                        backgroundColor: techZeroColors.green.main,
                        borderColor: techZeroColors.white.main,
                        ':hover': {
                            backgroundColor: techZeroColors.green.dark,
                            borderColor: techZeroColors.white.main,
                        },
                    },
                    link: {
                        color: techZeroColors.white.main,
                        ':hover': {
                            color: techZeroColors.black.main,
                        },
                    },
                    text: {
                        color: techZeroColors.white.main,
                        ':hover': {
                            color: techZeroColors.black.main,
                        },
                    },
                },
                tertiary: {
                    contained: {
                        backgroundColor: techZeroColors.black.main,
                        color: techZeroColors.green.main,
                        borderColor: techZeroColors.black.main,
                        ':hover': {
                            backgroundColor: techZeroColors.black.main,
                            color: techZeroColors.white.main,
                            borderColor: techZeroColors.black.main,
                        },
                    },
                    ghost: {
                        backgroundColor: techZeroColors.white.main,
                        color: techZeroColors.green.main,
                        ':hover': {
                            backgroundColor: techZeroColors.white.main,
                            color: techZeroColors.green.main,
                            textDecoration: 'underline',
                        },
                    },
                    outlined: {
                        backgroundColor: techZeroColors.black.main,
                        color: techZeroColors.green.main,
                        borderColor: techZeroColors.white.main,
                        ':hover': {
                            backgroundColor: techZeroColors.white.main,
                            color: techZeroColors.black.main,
                            borderColor: techZeroColors.green.main,
                        },
                    },
                    link: {
                        color: techZeroColors.white.main,
                        ':hover': {
                            color: techZeroColors.green.dark,
                        },
                    },
                    text: {
                        color: techZeroColors.white.main,
                        ':hover': {
                            color: techZeroColors.green.dark,
                        },
                    },
                },
            },
            container: {
                light: {
                    backgroundColor: techZeroColors.white.main,
                },
                mid: {
                    backgroundColor: techZeroColors.green.main,
                },
                dark: {
                    backgroundColor: techZeroColors.black.main,
                },
                none: {
                    backgroundColor: 'none',
                },
            },
            card: {
                light: {
                    backgroundColor: techZeroColors.white.main,
                    borderColor: techZeroColors.black.main,
                },
                midLight: {
                    backgroundColor: techZeroColors.white.main,
                },
                mid: {
                    borderColor: techZeroColors.white.main,
                },
            },
            chip: {
                secondary: {
                    ':hover': {
                        borderColor: techZeroColors.green.dark,
                        backgroundColor: techZeroColors.green.dark,
                        color: techZeroColors.white.main,
                    },
                },
            },
            typography: {
                link: {
                    color: techZeroColors.green.main,
                    ':hover': {
                        color: techZeroColors.green.dark,
                    },
                },
            },
        },
        name: 'TechZeroTheme',
        spacing: {
            sm: '1.2rem',
            md: '2rem',
        },
        typography: {
            fontFamily: '"DM Sans", Arial, Helvetica, sans-serif',
        },
    },
};

export default theme;
