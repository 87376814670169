import { Button } from '@krakentech/coral';
import { storyblokEditable } from '@storyblok/react';

import { ButtonStoryblok } from '@/types/generated/storyblok/schema';

type DynamicButtonProps = {
    blok: ButtonStoryblok;
};

const DynamicButton = ({ blok }: DynamicButtonProps) => (
    <Button
        {...storyblokEditable(blok)}
        color={blok.color}
        type={blok.type}
        href={blok.href}
        variant={blok.variant}
        fullWidth={blok.fullWidth}
        disabled={blok.disabled}
    >
        {blok.text}
    </Button>
);

export default DynamicButton;
