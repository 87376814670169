import { Table } from '@krakentech/coral';
import { storyblokEditable } from '@storyblok/react';
import { DataTableStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicTableProps = {
    blok: DataTableStoryblok;
};

const DynamicTable = ({ blok }: DynamicTableProps) => {
    if (!blok?.data) {
        return <StoryblokMissingField blok="Table" field="Data" />;
    }

    const columns = blok.data.thead?.map(({ value }) => ({
        key: value || '',
        title: value || '',
    }));

    const data = blok.data.tbody?.map(({ body }, rowIndex) => {
        return body.reduce<Record<string, unknown>>(
            (accumulator, current, colIndex) => {
                return {
                    ...accumulator,
                    key: rowIndex + 1,
                    [columns[colIndex].title]: current.value,
                };
            },
            {}
        );
    });

    return (
        <div {...storyblokEditable(blok)}>
            {/* "Table.types.d.ts(19, 5): 'key' is declared here." this is erroneous typing in Coral */}
            {/* @ts-ignore-next-line */}
            <Table columns={columns} data={data} />
        </div>
    );
};

export default DynamicTable;
