import { Card } from '@krakentech/coral';
import {
    SbBlokData,
    StoryblokComponent,
    storyblokEditable,
} from '@storyblok/react';
import { StyledCard } from './index.styled';
import { CardStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicCardProps = {
    blok: CardStoryblok;
};

const DynamicCard = ({
    blok,
    blok: { src, orientation, position, width, height, fullHeight },
}: DynamicCardProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Card" field="Blocks" />;
    }
    return (
        <StyledCard {...storyblokEditable(blok)} fullHeight={fullHeight}>
            <Card theme={blok.theme} fullHeight={fullHeight}>
                <Card.Header title={blok.title} extra={blok.extra} />
                <Card.Image
                    {...(src?.filename
                        ? {
                              src: src.filename,
                              orientation,
                              position,
                              width,
                              height,
                          }
                        : undefined)}
                />
                {blok.blocks.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent
                        blok={nestedBlok}
                        key={nestedBlok._uid}
                    />
                ))}
            </Card>
        </StyledCard>
    );
};

export default DynamicCard;
