import { useState } from 'react';
import { CoralThemeProvider } from '@krakentech/coral';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StoryblokComponent } from '@storyblok/react';
import { CoralStoryblokProvider } from '@krakentech/coral-storyblok';
import theme from '@/theme';

type ProvidersProps = {
    children: React.ReactNode;
};

const Providers = ({ children }: ProvidersProps) => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnMount: false,
                        refetchOnWindowFocus: false,
                        refetchOnReconnect: false,
                    },
                },
            })
    );

    return (
        <QueryClientProvider client={queryClient}>
            <CoralStoryblokProvider StoryblokComponent={StoryblokComponent}>
                <CoralThemeProvider config={theme}>
                    {children}
                </CoralThemeProvider>
            </CoralStoryblokProvider>
        </QueryClientProvider>
    );
};

export default Providers;
