import styled from 'styled-components';
import { techZeroColors } from '@/utils/constants';

export const MaintenanceContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${techZeroColors.green.main};
    color: ${techZeroColors.white.main};
`;
