import NextLink from 'next/link';
import { storyblokEditable } from '@storyblok/react';

import { Stack } from '@krakentech/coral';
import StoryblokImage from '@/components/StoryblokImage';
import { ImageStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicImageProps = {
    blok: ImageStoryblok;
};

const DynamicImage = ({ blok }: DynamicImageProps) => {
    if (!blok?.image) {
        return <StoryblokMissingField blok="Image" field="Image" />;
    }

    const Image = (
        <Stack justifyContent="center" {...storyblokEditable(blok)}>
            <StoryblokImage
                src={blok.image.filename}
                alt={blok.image.alt ? blok.image.alt : ''}
                width={blok.widthOverride}
                height={blok.heightOverride}
                caption={blok.caption}
            />
        </Stack>
    );

    if (blok.link && blok.link.linktype) {
        // internal link
        if (blok.link.linktype === 'story' && blok.link.story) {
            return (
                <NextLink href={blok.link.story.url} passHref>
                    {/* disabling this rule as it will be solved with the next/link updates in Next 13  */}
                    {/* eslint-disable-next-line */}
                    <a target={blok.link.target}>{Image}</a>
                </NextLink>
            );
        }

        return (
            <a
                href={blok.link.url}
                target={blok.link.target}
                rel={blok.link.rel}
                title={blok.link.title}
                {...storyblokEditable(blok)}
            >
                {Image}
            </a>
        );
    }

    return Image;
};

export default DynamicImage;
