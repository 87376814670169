export const regionsText = {
    northEast: 'the North East',
    northWest: 'the North West',
    yorkshire: 'Yorkshire',
    eastMidlands: 'the East Midlands',
    westMidlands: 'the West Midlands',
    east: 'the East',
    london: 'London',
    southEast: 'the South East',
    southWest: 'the South West',
};
