import { Blockquote } from '@krakentech/coral';
import {
    SbBlokData,
    StoryblokComponent,
    storyblokEditable,
} from '@storyblok/react';
import { BlockquoteStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicBlockquoteProps = {
    blok: BlockquoteStoryblok;
};

const DynamicBlockquote = ({ blok }: DynamicBlockquoteProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Blockquote" field="Blocks" />;
    }

    return (
        <div {...storyblokEditable(blok)}>
            <Blockquote gap={blok.gap}>
                {blok.src && <Blockquote.Image src={blok.src.filename} />}
                <Blockquote.Body>
                    <Blockquote.Quote>
                        {blok.blocks.map((nestedBlok: SbBlokData) => (
                            <StoryblokComponent
                                blok={nestedBlok}
                                key={nestedBlok._uid}
                            />
                        ))}
                    </Blockquote.Quote>
                    <Blockquote.Author>{blok.author}</Blockquote.Author>
                </Blockquote.Body>
            </Blockquote>
        </div>
    );
};

export default DynamicBlockquote;
