import styled from 'styled-components';
import { TypographyStoryblok } from '@/types/generated/storyblok/schema';

export const StyledTypography = styled.div<
    Pick<TypographyStoryblok, 'align' | 'gap'>
>`
    text-align: ${({ align }) => align};

    > * {
        margin-bottom: ${({ gap, theme }) =>
            gap && `${parseFloat(theme.spacing[gap])}rem`};
    }
`;
