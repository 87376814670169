import DynamicPage from './DynamicPage';
import DynamicTypography from './DynamicTypography';
import DynamicChip from './DynamicChip';
import DynamicLink from './DynamicLink';
import DynamicCard from './DynamicCard';
import DynamicTooltip from './DynamicTooltip';
import DynamicAlert from './DynamicAlert';
import DynamicButton from './DynamicButton';
import DynamicImage from './DynamicImage';
import DynamicContainer from './DynamicContainer';
import DynamicWell from './DynamicWell';
import DynamicAccordion from './DynamicAccordion';
import DynamicTable from './DynamicTable';
import DynamicDialog from './DynamicDialog';
import DynamicTwitterEmbed from './DynamicTwitterEmbed';
import DynamicPageTitle from './DynamicPageTitle';
import DynamicYouTubeEmbed from './DynamicYouTubeEmbed';
import DynamicStack from './DynamicStack';
import DynamicGrid, { DynamicGridItem } from './DynamicGrid';
import DynamicTypeform from './DynamicTypeform';
import DynamicBlockquote from './DynamicBlockquote';
import DynamicBanner from './DynamicBanner';
import DynamicHero from './DynamicHero';
import DynamicSignatory from './SignatoriesBlok';

import DynamicGreenJobsMap from './GreenJobsMap';
import { ComponentDictionaryType } from '@/types/storyblok';

// This is the mapping from the Storyblok component to
// the code thatwill render that component.
export const components: ComponentDictionaryType = {
    page: DynamicPage,
    typography: DynamicTypography,
    chip: DynamicChip,
    link: DynamicLink,
    card: DynamicCard,
    tooltip: DynamicTooltip,
    alert: DynamicAlert,
    button: DynamicButton,
    image: DynamicImage,
    container: DynamicContainer,
    well: DynamicWell,
    accordion: DynamicAccordion,
    dataTable: DynamicTable,
    dialog: DynamicDialog,
    twitterEmbed: DynamicTwitterEmbed,
    pageTitle: DynamicPageTitle,
    youTubeEmbed: DynamicYouTubeEmbed,
    stack: DynamicStack,
    grid: DynamicGrid,
    gridItem: DynamicGridItem,
    typeform: DynamicTypeform,
    blockquote: DynamicBlockquote,
    banner: DynamicBanner,
    hero: DynamicHero,
    signatory: DynamicSignatory,
    greenJobsMap: DynamicGreenJobsMap,
};
