import Image from 'next/image';
import { MaintenanceContainer } from './index.styled';

export const MaintenanceCover = () => (
    <MaintenanceContainer>
        <Image
            src="/images/tech-zero-white.png"
            alt="Tech Zero logo"
            height={200}
            width={200}
        />
        <div>
            <h1>Tech Zero is currently under maintenance</h1>
            <h2>We will be up and running soon, please check back later!</h2>
        </div>
    </MaintenanceContainer>
);
