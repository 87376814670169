import styled, { css } from 'styled-components';
import { CardStoryblok } from '@/types/generated/storyblok/schema';

export const StyledCard = styled.div<Pick<CardStoryblok, 'fullHeight'>>`
    ${({ fullHeight }) =>
        fullHeight &&
        css`
            height: 100%;
        `}
`;
