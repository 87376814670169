export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

type PageViewArgs = {
    /**
     * The title of the page.
     */
    title?: string;
    /**
     * The URL of the page.
     */
    url: string;
};

export const pageView = ({ title, url }: PageViewArgs) => {
    // @ts-ignore
    window.gtag('config', GA_TRACKING_ID, {
        page_title: title,
        page_path: url,
    });
};

type EventArgs = {
    /**
     *  The value that will appear as the event action in Google Analytics Event reports.
     */
    action: string;
    /**
     * The category of the event.
     */
    category?: string;
    /**
     * The label of the event.
     */
    label: string;
    /**
     * A non-negative integer that will appear as the event value.
     */
    value?: number;
};

export const event = ({
    action,
    category = 'general',
    label,
    value,
}: EventArgs) => {
    // @ts-ignore
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
    });
};
