import styled, { css } from 'styled-components';
import { HeroStoryblok } from '@/types/generated/storyblok/schema';

export const StyledHero = styled.div<Partial<HeroStoryblok>>`
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    line-height: 6rem;
    justify-content: center;

    ${({ theme, large, imageUrl }) => css`
        background: linear-gradient(
                ${theme.color.base.dark + '66'},
                ${theme.color.base.dark + '66'}
            ),
            url(${imageUrl});

        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        ${large
            ? css`
                  min-height: 50vh;
                  height: auto;
                  padding: 5rem 0;
              `
            : css`
                  min-height: 22rem;
                  padding: 6rem 0 4rem;
              `};
    `}
`;
