import { render } from 'storyblok-rich-text-react-renderer-ts';
import { storyblokEditable } from '@storyblok/react';
import { StyledTypography } from './index.styled';
import {
    getMarkResolvers,
    getNodeResolvers,
    getDefaultStringResolver,
} from '@/utils/storyblok';
import { TypographyStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicTypographyProps = {
    blok: TypographyStoryblok;
};

const DynamicTypography = ({ blok }: DynamicTypographyProps) => {
    if (!blok?.content) {
        return <StoryblokMissingField blok="Typography" field="Content" />;
    }
    return (
        <StyledTypography
            {...storyblokEditable(blok)}
            align={blok.align}
            gap={blok.gap}
        >
            {render(blok.content, {
                markResolvers: getMarkResolvers(),
                nodeResolvers: getNodeResolvers('primary'),
                defaultStringResolver: (str) => getDefaultStringResolver(str),
            })}
        </StyledTypography>
    );
};

export default DynamicTypography;
