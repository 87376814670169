import styled, { css } from 'styled-components';
import { CoralColors } from '@krakentech/coral';

import { WellTheme } from './index.types';

const getThemedWellStyles = (color: CoralColors, wellTheme?: WellTheme) => {
    if (!wellTheme) {
        return;
    }

    const wellThemeColorMap: Record<
        WellTheme,
        { backgroundColor: string; color: string }
    > = {
        light: {
            backgroundColor: color.surface.dark,
            color: color.text.light,
        },
        mid: {
            backgroundColor: color.surface.light,
            color: color.text.dark,
        },
        dark: {
            backgroundColor: color.base.dark,
            color: color.text.dark,
        },
        none: {
            backgroundColor: 'none',
            color: 'inherit',
        },
    };

    return css`
        background-color: ${wellThemeColorMap[wellTheme].backgroundColor};
        color: ${wellThemeColorMap[wellTheme].color};
    `;
};

export const StyledWell = styled.div<{
    wellTheme?: WellTheme;
}>`
    ${({ wellTheme, theme: { spacing, color } }) => css`
        margin-bottom: ${spacing.lg};
        padding-top: ${spacing.xl};
        padding-bottom: ${spacing.xl};
        ${getThemedWellStyles(color, wellTheme)};
    `}

    @media (max-width: ${({ theme }) => theme.breakpoints.md / 16}em) {
        padding-top: ${({ theme }) => theme.spacing.sm};
        padding-bottom: ${({ theme }) => theme.spacing.sm};
    }
`;
