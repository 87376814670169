import { useEffect, useState } from 'react';
import Script from 'next/script';
import { Loader, Typography } from '@krakentech/coral';
import { storyblokEditable } from '@storyblok/react';
import { TweetEmbedResponseData } from '../../../types/TwitterEmbed/index.types';
import { StyledTweetEmbed } from './index.styled';
import { TwitterEmbedStoryblok } from '@/types/generated/storyblok/schema';

type DynamicTwitterEmbedProps = {
    blok: TwitterEmbedStoryblok;
};

const DynamicTwitterEmbed = ({ blok }: DynamicTwitterEmbedProps) => {
    const [data, setData] = useState<TweetEmbedResponseData>();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch(
            `/api/get-tweet-embed?tweetUrl=${blok.tweetUrl}&theme=${blok.theme}`
        )
            .then((res) => res.json())
            .then((data) => {
                setData(data);
                setLoading(false);
            });
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    if (isLoading) {
        return <Loader type="linear" />;
    }

    if (!data) {
        return (
            <Typography>
                Unable to load{' '}
                <a href={blok.tweetUrl} target="_blank" rel="noreferrer">
                    Tweet
                </a>
            </Typography>
        );
    }

    return (
        <StyledTweetEmbed align={blok.align} {...storyblokEditable(blok)}>
            <div dangerouslySetInnerHTML={{ __html: data.html }} />
            <Script src="https://platform.twitter.com/widgets.js" />
        </StyledTweetEmbed>
    );
};

export default DynamicTwitterEmbed;
