import { Fragment, useState } from 'react';
import { Button, Container, Dialog, Typography } from '@krakentech/coral';
import { EnglandMap } from '../assets/englandMap';
import { Regions } from '../types';
import { regionsText } from '../consts';
import { StyledGreenJobsMap } from './greenJobsMap.styled';
import { useGreenJobs } from '@/hooks/greenJobs/greenJobs';

export const GreenJobsMap = () => {
    const [selectedRegion, setSelectedRegion] = useState<Regions | null>(null);
    const { data: allGreenJobs } = useGreenJobs(true);

    const greenJobsFilteredByRegion = allGreenJobs?.filter(
        ({ region }) => region === selectedRegion
    );

    return (
        <>
            <Container maxHeight={850} lg={{ paddingX: 'xl' }}>
                <StyledGreenJobsMap>
                    <EnglandMap handleRegionClick={setSelectedRegion} />
                </StyledGreenJobsMap>
            </Container>
            <Dialog
                ariaLabelledBy="List of jobs based on region"
                open={selectedRegion !== null}
                onClose={() => setSelectedRegion(null)}
            >
                {selectedRegion && (
                    <Typography variant="h3" component="h2">
                        Jobs in {regionsText[selectedRegion]}
                    </Typography>
                )}
                <Container paddingY="sm">
                    {greenJobsFilteredByRegion?.length ? (
                        greenJobsFilteredByRegion.map(
                            ({ job_title, company, link }) => (
                                <Fragment key={job_title}>
                                    <Typography>
                                        <Button
                                            variant="link"
                                            color="secondary"
                                            href={link.url}
                                            size="xs"
                                        >
                                            {job_title} at {company}
                                        </Button>
                                    </Typography>
                                </Fragment>
                            )
                        )
                    ) : (
                        <Typography variant="h4">
                            There are currently no jobs available in this region
                        </Typography>
                    )}
                </Container>
            </Dialog>
        </>
    );
};
