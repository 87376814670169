import styled from 'styled-components';

type StyledFigureProps = {
    width: number;
};
export const StyledFigure = styled.figure<StyledFigureProps>`
    position: relative;
    max-width: ${({ width }) => `${width / 10}rem`};
    margin: 0 auto;
`;

type StyledImageContainerProps = {
    maxWidth: number;
    maxHeight: number;
};
export const StyledImageContainer = styled.div<StyledImageContainerProps>`
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: ${({ maxWidth }) => `${maxWidth / 10}rem`};
    max-height: ${({ maxHeight }) => `${maxHeight / 10}rem`};
`;

type StyledSvgProps = {
    width: number | undefined;
    height: number | undefined;
};
export const StyledSvg = styled.img<StyledSvgProps>`
    position: relative;
    display: block;
    max-width: ${({ width }) => (width ? `${width / 10}rem` : 'none')};
    max-height: ${({ height }) => (height ? `${height / 10}rem` : 'none')};
`;

export const StyledFigCaption = styled.figcaption`
    display: block;
    padding: ${({ theme }) => theme.spacing.sm} 0;
`;
