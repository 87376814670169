import { Typography, Button } from '@krakentech/coral';
import { Footer as CoralFooter } from '@krakentech/coral-organisms';
import { mainLinkMenu, subLinkMenu } from './content';

const Footer = () => (
    <CoralFooter>
        <CoralFooter.ListContainer variant="left">
            <CoralFooter.List>
                {mainLinkMenu.map((menuItem) => (
                    <CoralFooter.ListItem key={menuItem.text}>
                        <Typography
                            color="light"
                            variant="body1"
                            component="span"
                        >
                            <Button
                                variant="link"
                                color="secondary"
                                href={menuItem.href}
                            >
                                {menuItem.text}
                            </Button>
                        </Typography>
                    </CoralFooter.ListItem>
                ))}
            </CoralFooter.List>
        </CoralFooter.ListContainer>

        <CoralFooter.ListContainer variant="center">
            <CoralFooter.List>
                {subLinkMenu.map((menuItem) => (
                    <CoralFooter.ListItem key={menuItem.text}>
                        <Typography variant="body1" component="span">
                            <Button
                                variant="link"
                                color="secondary"
                                href={menuItem.href}
                            >
                                {menuItem.text}
                            </Button>
                        </Typography>
                    </CoralFooter.ListItem>
                ))}
            </CoralFooter.List>
        </CoralFooter.ListContainer>
        <CoralFooter.Text>
            <Typography variant="body2">
                Octopus Energy Ltd is a company registered in England and Wales.
                <br />
                Registered number: 09263424. Registered office: UK House, 5th
                floor, 164-182 Oxford Street, London, W1D 1NN.
            </Typography>
        </CoralFooter.Text>
        <CoralFooter.ListContainer variant="right"></CoralFooter.ListContainer>
    </CoralFooter>
);

export default Footer;
