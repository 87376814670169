import NextLink from 'next/link';

import { LinkProps } from './index.types';
import { StyledLink } from './index.styled';

const Link = ({ href, children, onClick, color = 'light' }: LinkProps) => {
    return (
        <NextLink href={href} passHref>
            <StyledLink color={color} onClick={onClick}>
                {children}
            </StyledLink>
        </NextLink>
    );
};

export default Link;
