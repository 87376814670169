const appConfig = {
    appName: 'Tech Zero',
    appDescription:
        "We're the climate action group for tech companies of all sizes committed to fighting the climate crisis.",
    apiEndpointDev: '/api/graphql',
    apiEndpointPreview: '/api/graphql',
    apiEndpointProd: '/api/graphql',
    localhost: 'localhost:3000',
};

export default appConfig;
