export const BLOG_LIST_PAGE_SIZE = 12;
export const FAQ_LIST_PAGE_SIZE = 4;
// the data:uri used in the onBlurDataURL for next/images
export const BLUR_DATA_URI =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAhSURBVHgB7cuxDQAACAIw9P9ribvcwAzdO+Q9DAtTQ0oQ5noD72NhZLkAAAAASUVORK5CYII=';

export const techZeroColors = {
    green: {
        main: '#11743C',
        dark: '#0c532b',
    },
    white: {
        main: '#FFFFFF',
    },
    black: {
        main: '#000000',
    },
};
