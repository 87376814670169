import { useCoralTheme } from '@krakentech/coral';
import NextProgress from 'next-progress';

const NextProgressBar = () => {
    const theme = useCoralTheme();
    return (
        <NextProgress
            color={theme.color.primary.main}
            delay={250}
            height="4px"
            options={{ showSpinner: false }}
        />
    );
};

export default NextProgressBar;
