import { Typography } from '@krakentech/coral';
import { storyblokEditable } from '@storyblok/react';
import { StyledPageTitle } from './index.styled';
import { PageTitleStoryblok } from '@/types/generated/storyblok/schema';

type DynamicPageTitleProps = {
    blok: PageTitleStoryblok;
};

const DynamicPageTitle = ({ blok }: DynamicPageTitleProps) => (
    <StyledPageTitle lineLength={blok.lineLength} {...storyblokEditable(blok)}>
        {blok.secondaryText && (
            <Typography variant="h6" component="span">
                {blok.secondaryText}
            </Typography>
        )}

        <Typography variant="homepageTitle" component="h1">
            {blok.text}
        </Typography>
    </StyledPageTitle>
);

export default DynamicPageTitle;
