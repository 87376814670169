import { ImageProps as NextImageProps } from 'next/image';
import { GraphQLClient } from 'graphql-request';
import {
    ISbStoryParams,
    ISbStoriesParams,
    getStoryblokApi,
} from '@storyblok/react';
import {
    RenderOptionsProps,
    Mark,
    Block,
} from 'storyblok-rich-text-react-renderer-ts';
import {
    Typography,
    TypographyProps,
    TypographyColor,
} from '@krakentech/coral';

import Link from '@/components/Link';

import { BLOG_LIST_PAGE_SIZE } from '@/utils/constants';

export const storyblokGraphQLClient = (preview = false): GraphQLClient =>
    new GraphQLClient('https://gapi.storyblok.com/v1/api', {
        headers: {
            version: preview ? 'draft' : 'published',
            token: preview
                ? String(process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_ACCESS_TOKEN)
                : String(process.env.NEXT_PUBLIC_STORYBLOK_PUBLIC_ACCESS_TOKEN),
        },
    });

export const getStory = async (path: string, params: ISbStoryParams) => {
    const storyblokApi = getStoryblokApi();
    const { data } = await storyblokApi.get(path, params);

    if (data?.story) {
        return data.story;
    }

    return null;
};

export const getStories = async (path: string, params: ISbStoriesParams) => {
    const storyblokApi = getStoryblokApi();
    const { data } = await storyblokApi.get(path, params);

    if (data?.stories && data?.stories.length) {
        return data.stories;
    }

    return [];
};

export const getDatasourceEntries = async (datasource: string) => {
    const storyblokApi = getStoryblokApi();
    const { data } = await storyblokApi.get(`cdn/datasource_entries`, {
        datasource,
    });

    if (data?.datasource_entries && data?.datasource_entries.length) {
        return data.datasource_entries;
    }

    return [];
};

export const getTotalBlogPages = (totalPosts: number) =>
    Math.ceil(totalPosts / BLOG_LIST_PAGE_SIZE);

/**
 * Storyblok's Image Service allows us optimize an image served from their CDN
 * Appending '/m/' converts the image to webp
 * https://www.storyblok.com/docs/image-service
 */
export const optimizeStoryblokImage = (src: string | NextImageProps['src']) =>
    `${src}/m/`;

type MarkResolversType = RenderOptionsProps['markResolvers'];
export const getMarkResolvers = (): MarkResolversType => ({
    [Mark.LINK]: (children, { href, target, linktype }) => {
        if (!href) {
            console.error('Link does not have an href');
            return null;
        }
        if (linktype === 'email') {
            return <a href={`mailto:${href}`}>{children}</a>;
        }
        if (href.match(/^(https?:)?\/\//)) {
            return (
                // Type 'LinkTarget' is not assignable to type 'HTMLAttributeAnchorTarget | undefined'.
                // @ts-ignore
                <a href={href} target={target}>
                    {children}
                </a>
            );
        }
        return <Link href={href}>{children}</Link>;
    },
    /* Other possible marks */
    // [Mark.BOLD]: (children) => {},
    // [Mark.ITALIC]: (children) => {},
    // [Mark.STRIKE]: (children) => {},
    // [Mark.UNDERLINE]: (children) => {},
    // [Mark.CODE]: (children) => {},
    // [Mark.STYLED]: (children, { class }) => {},
});

type NodeResolversType = RenderOptionsProps['nodeResolvers'];
export const getNodeResolvers = (
    color?: TypographyColor
): NodeResolversType => ({
    [Block.HEADING]: (children, { level }) => (
        <Typography
            color={color}
            variant={`h${level}` as TypographyProps['variant']}
        >
            {children}
        </Typography>
    ),
    [Block.PARAGRAPH]: (children) => (
        <Typography color={color}>{children}</Typography>
    ),
    /* Other possible nodes */
    // [Block.CODEBLOCK]: (children, { class }) => {},
    // [Block.IMAGE]: (children, { src, alt, title }) => {},
    // [Block.QUOTE]: (children) => {},
    // [Block.OL]: (children) => {},
    // [Block.UL]: (children) => {},
    // [Block.LI]: (children) => {},
    // [Block.HR]: () => {},
    // [Block.BR]: () => {},
});

export const getDefaultStringResolver = (str: string) => (
    <Typography>{str}</Typography>
);
