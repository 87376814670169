import styled from 'styled-components';

export const StyledSkipToContent = styled.div`
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    padding: 0.8rem;
    background-color: ${({ theme }) => theme.color.secondary.main};
    transform: translateY(-150%);
    z-index: 60;

    &:focus-within {
        transform: translateY(0);
    }
`;
