export const INTERNAL_PATHS = {
    HOME: {
        path: '/',
    },
    FAQS: {
        path: '/help-and-faqs',
    },
    BLOG: {
        path: '/blog',
    },
    ABOUT_US: {
        path: '/about-us',
    },
    JOIN: {
        path: '/join',
    },
    RESOURCES: {
        path: '/resources/tech-zero-resources',
    },
    CONTACT_US: { path: '/contact-us' },
    COMMITMENTS: {
        path: '/commitments',
    },
    SIGNATORIES: {
        path: '/signatories',
    },
    PRIVACY_POLICY: {
        path: '/privacy',
    },
    TERMS: {
        path: '/terms',
    },
};
