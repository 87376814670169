import { Button, Link, Stack, Typography } from '@krakentech/coral';
import { Header } from '@krakentech/coral-organisms';
import Image from 'next/image';
import { menus } from './content';

const TechZeroHeader = () => {
    return (
        <>
            <Header currentLocale="en">
                <Header.MenuToggleButton closedText="Menu" openText="Back" />
                <Header.NavMenu>
                    <Header.NavMenuList>
                        <Stack direction="vertical" gap="md">
                            {menus.mainMenu.map((item, i) => (
                                <Header.NavMenuItem key={i}>
                                    <Button
                                        variant="text"
                                        color="tertiary"
                                        size="xs"
                                        href={item.href}
                                    >
                                        <Typography
                                            variant="h2"
                                            component="span"
                                            color="inherit"
                                        >
                                            {item.text}
                                        </Typography>
                                    </Button>
                                </Header.NavMenuItem>
                            ))}
                        </Stack>
                    </Header.NavMenuList>

                    <Header.NavMenuList>
                        {menus.subMenu.map((item, i) => (
                            <Header.NavMenuItem key={i}>
                                <Button
                                    variant="text"
                                    color="tertiary"
                                    href={item.href}
                                >
                                    <Typography
                                        variant="h5"
                                        component="span"
                                        color="inherit"
                                    >
                                        {item.text}
                                    </Typography>
                                </Button>
                            </Header.NavMenuItem>
                        ))}
                    </Header.NavMenuList>
                </Header.NavMenu>

                <Header.Logo>
                    <Stack justifyContent="center">
                        <Link href="/">
                            <Image
                                src="/images/tech-zero-white-small.png"
                                alt="Tech Zero logo"
                                width="40"
                                height="40"
                            />
                        </Link>
                    </Stack>
                </Header.Logo>
            </Header>
        </>
    );
};

export default TechZeroHeader;
