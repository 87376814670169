import { Alert } from '@krakentech/coral';
import {
    SbBlokData,
    StoryblokComponent,
    storyblokEditable,
} from '@storyblok/react';
import { AlertStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicAlertProps = {
    blok: AlertStoryblok;
};

const DynamicAlert = ({ blok }: DynamicAlertProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Alert" field="Blocks" />;
    }
    return (
        <div {...storyblokEditable(blok)}>
            <Alert severity={blok.severity} inline={blok.inline}>
                {blok.blocks.map((nestedBlok: SbBlokData) => (
                    <StoryblokComponent
                        blok={nestedBlok}
                        key={nestedBlok._uid}
                    />
                ))}
            </Alert>
        </div>
    );
};

export default DynamicAlert;
