import { useState } from 'react';
import { Dialog, Button, Typography } from '@krakentech/coral';
import {
    SbBlokData,
    StoryblokComponent,
    storyblokEditable,
} from '@storyblok/react';

import { StyledDialogContent } from './index.styled';

import { DialogStoryblok } from '@/types/generated/storyblok/schema';
import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicDialogProps = {
    blok: DialogStoryblok;
};

const DynamicDialog = ({ blok }: DynamicDialogProps) => {
    const [open, setOpen] = useState(false);

    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Dialog" field="Blocks" />;
    }
    return (
        <div {...storyblokEditable(blok)}>
            <Button
                onClick={() => setOpen(true)}
                fullWidth={blok.triggerButtonFullWidth}
            >
                {blok.triggerButtonLabel}
            </Button>
            <Dialog
                open={open}
                ariaLabelledBy={blok._uid}
                maxWidth={blok.width}
            >
                <StyledDialogContent>
                    <Typography variant="h3">{blok.title}</Typography>
                    <>
                        {blok.blocks.map((nestedBlok: SbBlokData) => (
                            <StoryblokComponent
                                blok={nestedBlok}
                                key={nestedBlok._uid}
                            />
                        ))}
                    </>
                    <Button variant="outlined" onClick={() => setOpen(false)}>
                        {blok.closeButtonLabel}
                    </Button>
                </StyledDialogContent>
            </Dialog>
        </div>
    );
};

export default DynamicDialog;
