import { storyblokEditable } from '@storyblok/react';
import { Card, Link, Typography } from '@krakentech/coral';
import { SignatoryStoryblok } from '@/types/generated/storyblok/schema';

type DynamicSignatoryProps = {
    blok: SignatoryStoryblok;
};

const DynamicSignatory = ({ blok }: DynamicSignatoryProps) => {
    return (
        <Card theme="light" {...storyblokEditable(blok)}>
            <Typography variant="h2" component="h3" color="inherit">
                {blok.name}
            </Typography>
            <Typography color="inherit">{blok.category}</Typography>
            <Typography color="inherit">
                Committed to be net zero by: {blok.net_zero_target}
            </Typography>
            <Link color="dark" href={blok.link}>
                {blok.link}
            </Link>
        </Card>
    );
};

export default DynamicSignatory;
