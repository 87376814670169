import styled from 'styled-components';

export const StyledGreenJobsMap = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    > svg > g > path:hover {
        fill: #4e8117;
        cursor: pointer;
    }
`;
