import { Container, CoralSpacing } from '@krakentech/coral';

import { StyledWell } from './index.styled';
import { WellTheme } from './index.types';

type WellProps = {
    children: React.ReactNode;
    padding?: keyof CoralSpacing | 'default';
    margin?: keyof CoralSpacing | 'default';
    theme?: WellTheme;
};

const Well = ({ children, theme }: WellProps) => (
    <StyledWell wellTheme={theme}>
        <Container padding="md" margin="md" theme={theme}>
            {children}
        </Container>
    </StyledWell>
);

export default Well;
