import { storyblokEditable } from '@storyblok/react';
import { Button, Container, Stack, Typography } from '@krakentech/coral';
import DynamicImage from '../DynamicImage';
import { StyledHero } from './index.styled';
import { HeroStoryblok } from '@/types/generated/storyblok/schema';
import { PageContainer } from '@/components/PageContainer';

type DynamicHeroProps = {
    blok: HeroStoryblok;
};

const DynamicHero = ({ blok }: DynamicHeroProps) => {
    return (
        <StyledHero
            {...storyblokEditable(blok)}
            large={blok.large}
            imageUrl={blok.hero_image?.filename}
        >
            <Stack justifyContent="center">
                <PageContainer>
                    <Container
                        paddingX="md"
                        margin="auto"
                        theme="none"
                        fullHeight
                        fullWidth
                        maxWidth={440}
                        md={{ maxWidth: 1200 }}
                    >
                        {blok.large ? (
                            <Stack
                                gap="md"
                                direction="vertical"
                                xs={{
                                    ...(blok.vertical && {
                                        direction: 'vertical-reverse',
                                    }),
                                }}
                                md={{
                                    ...(blok.vertical
                                        ? {
                                              gap: 'lg',
                                          }
                                        : {
                                              gap: 'xxxl',
                                              direction: 'horizontal',
                                          }),
                                }}
                            >
                                <Stack
                                    direction="vertical"
                                    gap="lg"
                                    xs={{
                                        ...(blok.vertical && {
                                            alignItems: 'center',
                                        }),
                                    }}
                                >
                                    <Typography
                                        variant="homepageTitle"
                                        xs={{
                                            ...(blok.vertical && {
                                                textAlign: 'center',
                                            }),
                                        }}
                                    >
                                        <strong>{blok.hero_title}</strong>
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        xs={{
                                            ...(blok.vertical && {
                                                textAlign: 'center',
                                            }),
                                        }}
                                    >
                                        <strong>{blok.hero_description}</strong>
                                    </Typography>
                                    {blok?.hero_button?.length &&
                                    blok.hero_button[0].text &&
                                    blok.hero_button[0].href ? (
                                        <Button
                                            color="secondary"
                                            variant="ghost"
                                            href={blok.hero_button[0].href}
                                        >
                                            <Typography>
                                                {blok.hero_button[0].text}
                                            </Typography>
                                        </Button>
                                    ) : null}
                                </Stack>

                                {blok?.small_image?.length &&
                                blok.small_image[0] &&
                                blok.small_image[0].image.filename ? (
                                    <DynamicImage blok={blok.small_image[0]} />
                                ) : null}
                            </Stack>
                        ) : (
                            <Typography variant="h1">
                                <strong>{blok.hero_title}</strong>
                            </Typography>
                        )}
                    </Container>
                </PageContainer>
            </Stack>
        </StyledHero>
    );
};

export default DynamicHero;
