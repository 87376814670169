import { Container, CoralSpacing } from '@krakentech/coral';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';

import { ContainerStoryblok } from '@/types/generated/storyblok/schema';

import StoryblokMissingField from '@/components/StoryblokMissingField';

type DynamicContainerProps = {
    blok: Omit<ContainerStoryblok, 'theme' | 'padding' | 'margin'> & {
        padding?: keyof CoralSpacing | 'default';
        margin?: keyof CoralSpacing | 'default';
        /* TODO - ideally these values will be exported from Coral, just like CoralSpacing */
        theme?: 'light' | 'dark' | 'mid' | 'none';
    };
};

const DynamicContainer = ({ blok }: DynamicContainerProps) => {
    if (!blok?.blocks || blok.blocks?.length < 1) {
        return <StoryblokMissingField blok="Container" field="Blocks" />;
    }
    return (
        <Container
            theme={blok.theme || 'none'}
            fullWidth={blok.fullWidth || false}
        >
            {blok.blocks.map((nestedBlok: SbBlokData) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
        </Container>
    );
};

export default DynamicContainer;
