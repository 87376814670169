import { Alert, Typography } from '@krakentech/coral';

type StoryblokMissingFieldProps = {
    blok: string;
    field: string;
};

const StoryblokMissingField = ({ blok, field }: StoryblokMissingFieldProps) => {
    return (
        <Alert severity="warning">
            <Typography variant="h3" color="inherit">
                {blok}
            </Typography>
            <Typography color="inherit">
                Please configure the <strong>{field}</strong> field for this
                block.
            </Typography>
        </Alert>
    );
};

export default StoryblokMissingField;
